<template>
    <div class="page-body">
        <div class="table-body">
            <div class="table-header">
                <div class="handle-box">
                    <table width="100%">
                        <tr>
                            <td style="width: 80px">公司名称：</td>
                            <td>
                                <el-input
                                        v-model="searchItem.companyName"
                                        size="small"
                                        placeholder="请输入公司名称"
                                ></el-input>
                            </td>
                            <td style="width: 80px">姓名：</td>
                            <td>
                                <el-input
                                        v-model="searchItem.personName"
                                        size="small"
                                        placeholder="请输入姓名"
                                ></el-input>
                            </td>
                            <td style="width: 80px">联系电话：</td>
                            <td>
                                <el-input
                                        v-model="searchItem.phone"
                                        size="small"
                                        placeholder="请输入联系电话"
                                ></el-input>
                            </td>
                            <td
                                    style="
                  width: 260px;
                  text-align: center;
                "
                            >
                                <el-button
                                        type="primary"
                                        icon="el-icon-search"
                                        size="small"
                                        @click="searchBtn"
                                >搜索
                                </el-button>
                                <el-button
                                        type="primary"
                                        icon="el-icon-refresh"
                                        size="small"
                                        @click="reset"
                                >重置
                                </el-button>
                                <el-button
                                        type="primary"
                                        icon="el-icon-circle-plus"
                                        v-if="hasAuthority('material_add')"
                                        size="small"
                                        @click="addObject"
                                >
                                    增加
                                </el-button>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="table-info">
                <div class="table-button">
                    <div>
                        <el-table
                                :data="tableData"
                                v-loading="loading"
                                element-loading-text="拼命加载中"
                                element-loading-spinner="el-icon-loading"
                                element-loading-background="rgba(0, 0, 0, 0.1)"
                                :max-height="tableHeight"
                                style="width: 100%; height: 100%"
                                :border="true"
                                :row-style="{ height: '40px' }"
                                :header-cell-style="{
                background: '#EBEEF8',
                height: '50px',
                'text-align': 'center',
              }"
                                :cell-style="{ padding: '0', height: lineHeight }"
                        >
                            <el-table-column
                                    type="index"
                                    width="50"
                                    label="排序"
                                    align="center"
                            ></el-table-column>
                            <el-table-column
                                    prop="companyName"
                                    label="公司"
                                    align="center"
                            ></el-table-column>
                            <el-table-column
                                    prop="name"
                                    label="姓名"
                                    align="center"
                                    show-overflow-tooltip
                            ></el-table-column>
                            <el-table-column
                                    prop="phone"
                                    label="联系电话"
                                    align="center"
                                    show-overflow-tooltip
                            ></el-table-column>
                            <el-table-column
                                    prop="idcard"
                                    label="身份证号"
                                    align="center"
                                    show-overflow-tooltip
                            ></el-table-column>
                            <el-table-column prop="id" label="操作" align="center">
                                <template slot-scope="scope">
                                    <div class="opertion">
                    <i class="iconfont iconbianji" title="编辑"
                            style="color: #20a0ff"
                            @click="editMaterial(scope.row)"
                    ></i
                    >
                                        <i class="iconfont iconshanchu" title="删除"
                                                style="color: red; margin-left: 20px"
                                                @click="deleteMaterial(scope.row)"
                                        ></i
                                        >
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <div class="table-data">
                    <Pagination
                            :pageSize="option.pageSize"
                            :pageTotal="totalPage"
                            :pageCurrent.sync="option.pageIndex"
                            :pageList="pageList"
                            @handlePageChange="pageChange"
                            @handleSizeChange="pageSizeChange"
                    />
                </div>
            </div>
            <el-dialog
                    :title="dialogName"
                    :visible.sync="dialogVisible"
                    width="30%"
                    :close-on-click-modal="false"
            >
                <div class="dialogdiv">
                    <div>
                        <p>公司：</p>
                        <el-select
                                style="width: 350px"
                                v-model="addoption.companyName"
                                placeholder="请选择公司" filterable
                        >
                            <el-option
                                    v-for="(item,index) in companyList"
                                    :key="index"
                                    :label="item"
                                    :value="item"
                            >
                            </el-option
                            >
                        </el-select>
                    </div>
                    <div>
                        <p>操作员：</p>
                        <el-input
                                style="width: 350px"
                                v-model="addoption.name"
                                placeholder="请输入操作员"
                        ></el-input>
                    </div>
                    <div>
                        <p>联系电话：</p>
                        <el-input
                                style="width: 350px"
                                v-model="addoption.phone"
                                placeholder="请输入联系电话"
                        ></el-input>
                    </div>
                    <div>
                        <p>身份证号：</p>
                        <el-input
                                style="width: 350px"
                                v-model="addoption.idcard"
                                placeholder="请输入身份证号"
                        ></el-input>
                    </div>
                </div>
                <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="saveObject">确 定</el-button>
        </span>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                pageList: [16, 20, 30, 50],
                tableHeight: "",
                tableData: [],
                option: {
                    pageSize: 16,
                    pageIndex: 1,
                },
                totalPage: 10,
                lineHeight: "",
                searchItem: {
                    companyName: "",
                    personName: "",
                    phone: "",
                    pageIndex: 1,
                    pageSize: 16,
                },
                depotType: 0,
                dialogVisible: false,
                dialogName: "",
                addoption: {
                    id: "",
                    companyName: "",
                    name: "",
                    phone: "",
                    idcard: "",
                },
                companyList: [],
                loading: false,
            };
        },
        methods: {
            getCompanylist() {
                this.$api.getCompanyNameList().then((res) => {
                    if (res.code == 200) {
                        this.companyList = res.data
                    } else {
                        this.$message.error(res.message);
                    }
                });
            },
            saveObject() {
                if (this.addoption.companyName == "") {
                    this.$message.error("请选择公司");
                    return;
                }
                if (this.addoption.name == "") {
                    this.$message.error("请输入姓名");
                    return;
                }
                if (this.addoption.phone == "" || (!/^1[123456789]\d{9}$/.test(this.addoption.phone))) {
                    this.$message.error("请输入正确的手机号");
                    return;
                }
                this.$api.saveDebugPerson(this.addoption).then((res) => {
                    if (res.code == 200) {
                        this.searchBtn();
                        this.dialogVisible = false;
                        this.$message.success(res.message);
                    } else {
                        this.$message.error(res.message);
                    }
                });
            },
            deleteMaterial(val) {
                this.addoption.id = val.id;
                this.$api.deleteDebugPerson(this.addoption).then((res) => {
                    if (res.code == 200) {
                        this.searchBtn();
                        this.$message.success(res.message);
                    } else {
                        this.$message.error(res.message);
                    }
                });
            },
            editMaterial(val) {
                this.dialogVisible = true;
                this.dialogName = "修改调试人员";
                this.addoption.id = val.id;
                this.addoption.companyName = val.companyName;
                this.addoption.name = val.name;
                this.addoption.phone = val.phone;
                this.addoption.idcard = val.idcard;
            },
            pageChange(option) {
                this.option.pageIndex = option;
                this.searchBtn();
            },
            pageSizeChange(val) {
                this.option.pageIndex = 1;
                this.option.pageSize = val;
                this.searchBtn();
            },
            searchBtn() {
                this.searchItem.pageIndex = this.option.pageIndex;
                this.searchItem.pageSize = this.option.pageSize;
                this.loading = true;
                this.$api.getDebugPerson(this.searchItem).then((res) => {
                    if (res.code == 200) {
                        this.tableData = res.data.content;
                        this.totalPage = res.data.totalElements;
                        this.loading = false;
                    }
                });
            },
            reset() {
                this.searchItem.companyName = "";
                this.searchItem.personName = "";
                this.searchItem.phone = "";
            },
            addObject() {
                this.dialogVisible = true;
                this.dialogName = "新增调试人员";
                this.addoption.id = "";
                this.addoption.companyName = "";
                this.addoption.name = "";
                this.addoption.phone = "";
                this.addoption.idcard = "";
            },
        },
        created() {
            this.tableHeight = (window.innerHeight) * 0.76
            this.searchBtn();
            this.getCompanylist()
        },
    };
</script>
<style lang="scss" scoped>
    .iconfont {
        font-family: "iconfont" !important;
        font-size: 24px;
        font-style: normal;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin-top: 3px;
        display: block;
        cursor: pointer;
    }

    .opertion {
        display: flex;
        justify-content: center;
    }

    .iconbianji {
        margin-right: 10px;
        color: #20a0ff;
    }

    .iconshanchu {
        margin-left: 10px;
        color: red;
    }

    .page-body {
        background-color: #ffffff;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .table-body {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .table-header {
        height: 50px;
        margin-top: 0.5%;
        background-color: #ffffff;
    }

    .handle-box {
        padding: 5px;
    }

    .table-info {
        height: 94%;
        background-color: #ffffff;
        display: flex;
        flex-direction: column;
    }

    .table-button {
        flex: 1;
        margin: 0 1%;
    }

    .table-data {
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    td {
        text-align: right;
        white-space: nowrap; /*控制单行显示*/
        overflow: hidden; /*超出隐藏*/
        text-overflow: ellipsis; /*隐藏的字符用省略号表示*/
    }

    .opertion {
        display: flex;
        justify-content: center;
    }

    .dialogdiv {
        div {
            text-align: center;
            margin-top: 10px;
        }

        p {
            width: 80px;
            text-align: left;
            display: inline-block;
        }
    }
</style>